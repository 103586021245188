<template>
    <div class="">
        <el-button type="primary" style="float: right;" @click="createLiveDialog=true">新增券类<i class="el-icon-plus el-icon--right"></i></el-button>
        <div>
            <el-table :data="tableData" border  style="width: 100%;" height="680">
                <el-table-column fixed prop="vdid" label="vdid" v-if="false"> </el-table-column>
                <el-table-column fixed prop="name" label="券类名称"> </el-table-column>
                <el-table-column fixed prop="vdname" label="抵用券名称"> </el-table-column>
                <el-table-column prop="description" label="抵用券说明"> </el-table-column>
                <el-table-column prop="coverImg" label="抵用券封面图"> </el-table-column>
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"  @click="deleteClick(scope.row.vdid)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount">
                </el-pagination>
        </div>
        <el-dialog title="新增抵用券" :visible.sync="createLiveDialog">
            <el-form :model="form">
                <el-form-item label="所属券类" :label-width="formLabelWidth">
                    <el-select v-model="form.vid" placeholder="请选择"  style="width: 485px;">
                        <el-option
                        v-for="item in vouchers"
                        :key="item.vid"
                        :label="item.name"
                        :value="item.vid" >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="抵用券名称" :label-width="formLabelWidth">
                    <el-input v-model="form.vdname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="抵用券说明" :label-width="formLabelWidth">
                    <el-input v-model="form.description" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="抵用券封面图" :label-width="formLabelWidth">
                    <el-input v-model="form.coverImg" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeWebLiveDialog">取 消</el-button>
                <el-button type="primary" @click="saveVoucherDetails()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: [],
                createLiveDialog:false,
                form: {
                    vid:'',
                    vdname: '',
                    description: '',
                    coverImg: ''
                },
                formLabelWidth: '130px',
                currentPage:1,
                pageSizes:[10,20,50,100],
                pageSize:10,
                totalCount:0,
                vouchers:[]
            }
        },
        created(){
            this.getAllVouchersDetails();
            this.getAllVouchers();
        },
        methods: {
            getAllVouchersDetails(){
                this.postRequest('/appManage/getAllVouchersDetails',{
                    page:this.currentPage,
                    pageSize:this.pageSize
                }).then((res) => {
                    if(res.data.data.code==200){
                        this.tableData=res.data.data.data
                        this.totalCount=res.data.data.totalCount
                    }
                });
            },
            saveVoucherDetails(){
                this.postRequest('/appManage/saveVoucherDetails',this.form).then((res)=>{
                    if(res.data.data.code==200){
                        this.createLiveDialog=false;
                        this.getAllVouchersDetails();
                        this.$message({
                            message: '新增成功',
                            type: 'success'
                        });
                    }
                    else{
                        this.createLiveDialog=false;
                        this.$message.error('创建失败');
                    }
                })
            },
            closeWebLiveDialog(){
                this.createLiveDialog=false;
                // this.cleanForm();
            },
            cleanForm(){
                this.form.name=''
                this.form.description=''
                this.form.videourl=''
                this.form.coverImgUrl=''
                this.form.status=''
            },
            deleteClick(e){
                this.postRequest('/appManage/delVoucherDetails',{id:e}).then((res)=>{
                    if(res.data.data.code==200){
                        this.getAllVouchersDetails();
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                    }
                    else{
                        this.$message.error('删除失败');
                    }
                })
            },
            handleClick(row) {
                this.form=row;
                this.createLiveDialog=true;
            },
            handleSizeChange(val) {
                this.pageSize=val;
                this.getAllVouchersDetails();
            },
            handleCurrentChange(val) {
                this.currentPage=val;
                this.getAllVouchersDetails();
            },
            getAllVouchers(){
                this.postRequest('/appManage/getAllVouchersNoPage',{
                    
                }).then((res) => {
                    if(res.data.data.code==200){
                        this.vouchers=res.data.data.data
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .containerWrapper{
      display: flex;
      flex-direction: row;
    }
</style>
<template>
    <div class="">
        <el-button type="primary" style="float: right;" @click="createLiveDialog=true">上传视频<i class="el-icon-upload el-icon--right"></i></el-button>
        <div>
            <el-table :data="tableData" border  style="width: 100%;" height="680">
                <el-table-column fixed prop="id" label="id" v-if="false"> </el-table-column>
                <el-table-column fixed prop="vname" label="视频名称"> </el-table-column>
                <el-table-column prop="vdescription" label="视频简介"> </el-table-column>
                <el-table-column prop="vurl" label="视频地址">
                    <template slot scope="{row,$index}"> 
                        <video controls style="height: 100px;width: 200px;">
                            <source :src="row.vurl" type="video/mp4" >
                        </video>
                    </template>
                </el-table-column>
                <el-table-column prop="coverImgUrl" label="视频封面图">
                    <template slot scope="{row,$index}"> 
                        <img :src="row.coverImgUrl" alt="" style="height: 100px" />
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                        <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">修改</el-button> -->
                        <el-button type="text" size="small"  @click="deleteClick(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- <div class="block"> -->
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount">
                </el-pagination>
            <!-- </div> -->
        </div>
        <el-dialog title="上传视频" :visible.sync="createLiveDialog">
            <el-form :model="form"
            v-loading="loading"
            element-loading-text="正在上传文件....."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            >
                <el-form-item label="视频名称" :label-width="formLabelWidth">
                    <el-input v-model="form.vname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="视频简介" :label-width="formLabelWidth">
                    <el-input v-model="form.vdescription" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="视频封面图" :label-width="formLabelWidth">
                    <el-upload
                        ref="upload"
                        class="upload-demo"
                        action=""
                        :auto-upload="false"
                        :before-remove="beforeRemove"
                        multiple
                        :on-preview="handlePreview"
                        :file-list="fileList"
                        :on-success="handleSuccess"
                        :on-remove="handleRemove"
                        :on-change="onChange"
                        :limit="1"
                        :on-exceed="handleExceed">
                        <el-button size="small" type="primary">选取图片</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="视频" :label-width="formLabelWidth">
                    <!-- <el-input v-model="form.vurl" autocomplete="off"></el-input> -->
                    <el-upload
                        ref="upload"
                        class="upload-demo"
                        action=""
                        :auto-upload="false"
                        :before-remove="beforeRemoveVideo"
                        multiple
                        :on-preview="handlePreviewVideo"
                        :file-list="videoList"
                        :on-success="handleSuccessVideo"
                        :on-remove="handleRemoveVideo"
                        :on-change="onChangeVideo"
                        :limit="1"
                        :on-exceed="handleExceedVideo">
                        <el-button size="small" type="primary">选取视频</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeWebLiveDialog">取 消</el-button>
                <el-button type="primary" @click="uploadVideo()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: [],
                createLiveDialog:false,
                form: {
                    vname: '',
                    vdescription: '',
                    vurl: '',
                    coverImgUrl:''
                },
                formLabelWidth: '130px',
                currentPage:1,
                pageSizes:[10,20,50,100],
                pageSize:10,
                totalCount:0,
                formdata:[],
                formvideodata:[],
                fileList:[],
                videoList:[],
                loading:false
            }
        },
        created(){
            this.getAllVideos();
        },
        methods: {
            getAllVideos(){
                this.postRequest('/appManage/getAllVideos',{
                    page:this.currentPage,
                    pageSize:this.pageSize
                }).then((res) => {
                    if(res.data.data.code==200){
                        this.tableData=res.data.data.data
                        this.totalCount=res.data.data.totalCount
                    }
                });
            },
            uploadVideo(){
                this.loading=true
                this.postRequest('/fileUpload/pictureUpload',this.formvideodata).then((res)=>{
                    if(res.data.data.code==200){
                        this.form.vurl=res.data.data.ossPath
                        this.postRequest('/fileUpload/pictureUpload',this.formdata).then((res)=>{
                            if(res.data.data.code==200){
                                this.form.coverImgUrl=res.data.data.ossPath
                                this.postRequest('/appManage/saveVideos',this.form).then((res)=>{
                                    if(res.data.data.code==200){
                                        this.createLiveDialog=false;
                                        this.loading=false
                                        this.getAllVideos();
                                        this.$message({
                                            message: '上传成功',
                                            type: 'success'
                                        });
                                        this.cleanForm();
                                    }
                                    else{
                                        this.createLiveDialog=false;
                                        this.$message.error('上传失败');
                                    }
                                })
                            }
                            else{
                                this.createLiveDialog=false;
                                this.$message.error('上传失败');
                            }
                        })
                    }
                    else{
                        this.createLiveDialog=false;
                        this.$message.error('上传失败');
                    }
                })



                
            },
            closeWebLiveDialog(){
                this.createLiveDialog=false;
                // this.cleanForm();
            },
            cleanForm(){
                this.form.name=''
                this.form.description=''
                this.form.videourl=''
                this.form.coverImgUrl=''
                this.form.status=''
            },
            deleteClick(e){
                this.postRequest('/appManage/delVideos',{id:e}).then((res)=>{
                    if(res.data.data.code==200){
                        this.getAllVideos();
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                    }
                    else{
                        this.$message.error('删除失败');
                    }
                })
            },
            handleClick(row) {
                this.form=row;
                this.createLiveDialog=true;
            },
            handleSizeChange(val) {
                this.pageSize=val;
                this.getAllVideos();
            },
            handleCurrentChange(val) {
                this.currentPage=val;
                this.getAllVideos();
            },

            onChange(file,fileList) {
                //通常文件上传是要用 FormData 格式的
                this.formdata = new FormData()
                this.formdata.append('file', file.raw)
                this.formdata.append('name', this.name)
                this.formdata.append('ossPath', 'https://assets.yixinger.com/img/')
                this.formdata.append('objectName', 'img/')
            },
            handleRemove(file, fileList) {
                this.fileList = fileList
                // 判断溢出的文件是否当前预览中的文件
                if (fileList.findIndex(item => item.uid === this.currentFile.uid) === -1) {
                    this.currentFile = null
                }
            },
            handlePreview(file) {
                this.currentFile = file
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length}个文件，共选择了 ${files.length + fileList.length}个文件`)
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name} ？`)
            },
            handleSuccess(response, file, fileList) {
                onsole.log("fileList---->"+fileList)
                //this.files.push(response)
            },



            onChangeVideo(file,fileList) {
                //通常文件上传是要用 FormData 格式的
                this.formvideodata = new FormData()
                this.formvideodata.append('file', file.raw)
                this.formvideodata.append('name', this.name)
                this.formvideodata.append('ossPath', 'https://assets.yixinger.com/video/')
                this.formvideodata.append('objectName', 'video/')
            },
            handleRemoveVideo(file, fileList) {
                this.videoList = fileList
                // 判断溢出的文件是否当前预览中的文件
                if (fileList.findIndex(item => item.uid === this.currentFile.uid) === -1) {
                    this.currentFile = null
                }
            },
            handlePreviewVideo(file) {
                this.currentFile = file
            },
            handleExceedVideo(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length}个文件，共选择了 ${files.length + fileList.length}个文件`)
            },
            beforeRemoveVideo(file, fileList) {
                return this.$confirm(`确定移除 ${file.name} ？`)
            },
            handleSuccessVideo(response, file, fileList) {
                onsole.log("fileList---->"+fileList)
                //this.files.push(response)
            },
            cleanForm(){
                this.form.vname=''
                this.form.vdescription=''
                this.form.vurl=''
                this.form.coverImgUrl=''
                this.formdata=[]
                this.formvideodata=[]
                this.fileList=[]
                this.videoList=[]
            },
        }
    }
</script>

<style scoped>
    .containerWrapper{
      display: flex;
      flex-direction: row;
    }
</style>
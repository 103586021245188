<template>
    <div class="">
        <el-button type="primary" style="float: right;" @click="createLiveDialog=true">创建直播<i class="el-icon-plus el-icon--right"></i></el-button>
        <div>
            <el-table :data="tableData" border  height="720">
                <el-table-column fixed prop="id" label="id" v-if="false"> </el-table-column>
                <el-table-column fixed prop="name" label="直播名称"> </el-table-column>
                <el-table-column prop="description" label="直播简介"> </el-table-column>
                <el-table-column prop="videourl" label="直播地址"> </el-table-column>
                <el-table-column prop="coverImgUrl" label="直播封面图">
                    <template slot scope="{row,$index}"> 
                        <img :src="row.coverImgUrl" alt="" style="height: 100px" />
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="是否开启跳转直播"> </el-table-column>
                <el-table-column fixed="right" prop="status" label="编辑直播跳转">
                    <template slot scope="{row,$index}">
                        
                        <el-radio-group v-model="row.status" v-if="row.status=='是'" size="small" @change="editJump(row)">
                            <el-radio-button label="是" checked="true" >开启</el-radio-button>
                            <el-radio-button label="否" >关闭</el-radio-button>
                        </el-radio-group>
                        <el-radio-group v-model="row.status" v-if="row.status=='否'" size="small" @change="editJump(row)">
                            <el-radio-button label="是" >开启</el-radio-button>
                            <el-radio-button label="否" checked="true" >关闭</el-radio-button>
                        </el-radio-group>

                        <!-- <el-button-group v-if="row.status=='是'" size="small">
                            <el-button type="primary" @click="editJump(row.id,0)">开启</el-button>
                            <el-button @click="editJump(row.id,1)">关闭</el-button>
                        </el-button-group>
                        <el-button-group v-if="row.status=='否'" size="small">
                            <el-button @click="editJump(row.id,0)">开启</el-button>
                            <el-button type="primary" @click="editJump(row.id,1)">关闭</el-button>
                        </el-button-group> -->
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                        <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">修改</el-button> -->
                        <el-button type="text" size="small"  @click="deleteClick(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog title="创建直播" :visible.sync="createLiveDialog">
            <el-form :model="form">
                <el-form-item label="直播名称" :label-width="formLabelWidth">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="直播简介" :label-width="formLabelWidth">
                    <el-input v-model="form.description" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="直播地址" :label-width="formLabelWidth">
                    <el-input v-model="form.videourl" autocomplete="off"></el-input>
                </el-form-item>
                
                <el-form-item label="直播封面图地址" :label-width="formLabelWidth">
                    <el-upload
                        ref="upload"
                        class="upload-demo"
                        action=""
                        :auto-upload="false"
                        :before-remove="beforeRemove"
                        multiple
                        :on-preview="handlePreview"
                        :file-list="fileList"
                        :on-success="handleSuccess"
                        :on-remove="handleRemove"
                        :on-change="onChange"
                        :limit="1"
                        :on-exceed="handleExceed">
                        <el-button size="small" type="primary">选取图片</el-button>
                    </el-upload>
                </el-form-item>

                <el-form-item label="是否开启跳转直播" :label-width="formLabelWidth">
                <el-select v-model="form.status" placeholder="请选择是否开启跳转直播">
                    <el-option label="是" value="是"></el-option>
                    <el-option label="否" value="否"></el-option>
                </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeWebLiveDialog">取 消</el-button>
                <el-button type="primary" @click="createWebLive()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: [],
                createLiveDialog:false,
                form: {
                    name: '',
                    description: '',
                    videourl: '',
                    coverImgUrl: '',
                    status: '',
                },
                formLabelWidth: '130px',
                fileList:[],
                formdata:[]
            }
        },
        created(){
            this.getWebLive();
        },
        methods: {
            getWebLive(){
                this.postRequest('/appManage/getWeblive','').then((res) => {
                    if(res.data.data.code==200){
                        this.tableData=res.data.data.data
                    }
                });
            },
            createWebLive(){
                this.postRequest('/fileUpload/pictureUpload',this.formdata).then((res)=>{
                    if(res.data.data.code==200){
                        this.form.coverImgUrl=res.data.data.ossPath
                        this.postRequest('/appManage/saveWebVideos',this.form).then((res)=>{
                            if(res.data.data.code==200){
                                this.createLiveDialog=false;
                                this.getWebLive();
                                this.$message({
                                    message: '创建成功',
                                    type: 'success'
                                });
                            }
                            else{
                                this.createLiveDialog=false;
                                this.$message.error('创建失败');
                            }
                        })
                    }
                })
            },
            closeWebLiveDialog(){
                this.createLiveDialog=false;
                // this.cleanForm();
            },
            cleanForm(){
                this.form.name=''
                this.form.description=''
                this.form.videourl=''
                this.form.coverImgUrl=''
                this.form.status=''
            },
            deleteClick(e){
                this.postRequest('/appManage/delWebVideos',{id:e}).then((res)=>{
                    if(res.data.data.code==200){
                        this.getWebLive();
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                    }
                    else{
                        this.$message.error('删除失败');
                    }
                })
            },
            handleClick(row) {
                this.form=row;
                this.createLiveDialog=true;
            },
            editJump(row){
                this.postRequest('/appManage/editWebStatus',{
                    id:row.id,
                    status:row.status
                }).then((res)=>{
                    if(res.data.data.code==200){
                        this.getWebLive();
                        this.$message({
                            message: '编辑成功',
                            type: 'success'
                        });
                    }
                    else{
                        this.$message.error('编辑失败');
                    }
                })

            },

            onChange(file,fileList) {
                //通常文件上传是要用 FormData 格式的
                this.formdata = new FormData()
                this.formdata.append('file', file.raw)
                this.formdata.append('name', this.name)
                this.formdata.append('ossPath', 'https://assets.yixinger.com/img/')
                this.formdata.append('objectName', 'img/')
            },
            handleRemove(file, fileList) {
                this.fileList = fileList
                // 判断溢出的文件是否当前预览中的文件
                if (fileList.findIndex(item => item.uid === this.currentFile.uid) === -1) {
                    this.currentFile = null
                }
            },
            handlePreview(file) {
                this.currentFile = file
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length}个文件，共选择了 ${files.length + fileList.length}个文件`)
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name} ？`)
            },
            handleSuccess(response, file, fileList) {
                onsole.log("fileList---->"+fileList)
                //this.files.push(response)
            },
        },
    }
</script>

<style scoped>
    .containerWrapper{
      display: flex;
      flex-direction: row;
    }
</style>
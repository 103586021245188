//api.js

// 引入 axios
import axios from "axios";
//引入 element-ui 信息
import {Message} from "element-ui";
import router from "@/router";

// 创建axios实例对象
const httptool = axios.create({
    //本地注释掉，只有打包部署是才会用
    baseURL: '/appManage',  //  注意!!  这里是全局统一加上了  '/api'  前缀,也就是说所有的接口都会加上'/api'前缀名
    // timeout: 5000
})

//传送json格式的post请求
export const postRequest = (url, params) => {
    return httptool.post(
        `${url}`,
        params,
    )
}

//传送json格式的put请求
export const putRequest = (url, params) => {
    return httptool.put(
        `${url}`,
        params,
    )
}

//传送json格式的get请求
export const getRequest = (url) => {
    return httptool.get(
        `${url}`,
    )
}

//传送json格式的delete请求
export const deleteRequest = (url, params) => {
    return httptool.delete(
        `${url}`,
        params,
    )
}

<template>
    <div class="">
        <!-- <el-button type="primary" style="float: right;" @click="createLiveDialog=true">批量修改用户类型<i class="el-icon-edit el-icon--right"></i></el-button> -->
        <div>
            <span style="float: left;padding-top: 10px;margin-left: 10px;">输入A类用户电话号码：</span>
            <el-input v-model="phoneA" 
                clearable 
                style="width:150px;float: left;margin-left: 10px;"
            ></el-input>
            <el-button type="primary" style="float: left;margin-left: 10px;" @click="openADialog">查询</el-button>

            <span style="float: left;padding-top: 10px;margin-left: 70px;">输入B类用户电话号码：</span>
            <el-input v-model="phoneB" 
                clearable 
                style="width:150px;float: left;margin-left: 10px;"
            ></el-input>
            <el-button type="primary" style="float: left;margin-left: 10px;" @click="openBDialog">查询</el-button>
        </div>
        <div>
            <el-table :data="tableData" border  style="width: 100%;" height="730">
                <el-table-column fixed prop="id" label="id" v-if="false"> </el-table-column>
                <el-table-column fixed prop="nickname" label="用户昵称"> </el-table-column>
                <el-table-column prop="phone" label="用户手机号"> </el-table-column>
                <el-table-column prop="vname" label="券名称"> </el-table-column>
                <el-table-column prop="grantPhone" label="发券人手机号"> </el-table-column>
                <el-table-column prop="sendtime" label="发券时间"> </el-table-column>
                <el-table-column prop="mark" label="发券备注"> </el-table-column>
                <el-table-column prop="writeOffPhone" label="核券人手机号"> </el-table-column>
                <el-table-column prop="writeOffTime" label="核券时间"> </el-table-column>
                
            </el-table>
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount">
                </el-pagination>
        </div>


        <el-dialog title="A类用户发券详情" :visible.sync="createADialog" style="width: 100%;">
            <span style="float: left;padding-top: 10px;margin-left: 10px;">A类用户电话号码：</span>
            <el-input v-model="phoneA" 
                clearable 
                :disabled="true"
                style="width:150px;float: left;margin-left: 10px; "
            ></el-input>
            <el-table :data="tableAData" border  style="width: 100%;" height="700" 
            ref="multipleTable"
            >
                <el-table-column fixed prop="vid" v-if="false" label="用户券id"> </el-table-column>
                <el-table-column fixed prop="name" label="券名称"> </el-table-column>
                <el-table-column prop="grantNum" label="发券数量"> </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"  @click="viewDetailA(scope.row.vid)">详情</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="canlNum" label="核券数量"> </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"  @click="viewDetailB(scope.row.vid)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>


        <el-dialog title="发券详情" :visible.sync="grantdetail" style="width: 100%;">
            <el-table :data="tableDetailData" border  style="width: 100%;" height="700" 
            ref="multipleTable"
            >
                <el-table-column fixed prop="vid" v-if="false" label="用户券id"> </el-table-column>
                <el-table-column fixed prop="name" label="用户名"> </el-table-column>
                <el-table-column prop="phone" label="用户手机号"> </el-table-column>
                <el-table-column prop="sendtime" label="发券时间"> </el-table-column>
                <el-table-column prop="mark" label="发券时备注"> </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog title="核券详情" :visible.sync="writeDetail" style="width: 100%;">
            <el-table :data="tableWriteData" border  style="width: 100%;" height="700" 
            ref="multipleTable"
            >
                <el-table-column fixed prop="vid" v-if="false" label="用户券id"> </el-table-column>
                <el-table-column fixed prop="name" label="用户名"> </el-table-column>
                <el-table-column prop="phone" label="用户手机号"> </el-table-column>
                <el-table-column prop="writeOffTime" label="核券时间"> </el-table-column>
            </el-table>
        </el-dialog>




        <el-dialog title="B类用户持券详情" :visible.sync="createBDialog" style="width: 100%;">
            <div>
                <span style="float: left;padding-top: 10px;margin-left: 10px;">B类用户电话号码：</span>
                <el-input v-model="phoneB" 
                    clearable 
                    :disabled="true"
                    style="width:150px;float: left;margin-left: 10px; "
                ></el-input>
                <el-table :data="tableBData" border  style="width: 100%;" height="400" 
                ref="multipleTable"
                >
                    <el-table-column fixed prop="name" label="券名称"> </el-table-column>
                    <el-table-column prop="expMark" label="券说明"> </el-table-column>
                    <el-table-column prop="grantName" label="发券人"> </el-table-column>
                    
                </el-table>
            </div>
            <div style="margin-top: 15px;">
                <span style="float: left;">B类所有用户持有数量（有效期内不含已过期已核销）</span>
                <el-table :data="tableCData" border  style="width: 100%;" height="400" 
                ref="multipleTable"
                >
                    <el-table-column fixed prop="name" label="券名称"> </el-table-column>
                    <el-table-column prop="totalNum" label="券数量"> </el-table-column>
                    
                </el-table>
            </div>
        </el-dialog>


    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: [],
                tableAData:[],
                tableBData:[],
                tableCData:[],
                tableDetailData:[],
                tableWriteData:[],
                form: {
                    userType: ''
                },
                formLabelWidth: '130px',
                currentPage:1,
                pageSizes:[15,30,50,100],
                pageSize:15,
                totalCount:0,
                phoneA:'',
                phoneB:'',
                createADialog:false,
                createBDialog:false,
                grantdetail:false,
                writeDetail:false,
            }
        },
        created(){
            this.getAllUserVouchersDetails();
        },
        methods: {
            getAllUserVouchersDetails(){
                this.postRequest('/appManage/getAllUserVouchersDetails',{
                    page:this.currentPage,
                    pageSize:this.pageSize
                }).then((res) => {
                    if(res.data.data.code==200){
                        this.tableData=res.data.data.data
                        this.totalCount=res.data.data.totalCount
                    }
                });
            },
            openADialog(){
                this.postRequest('/appManage/getAVouchersDetails',{
                    phoneA:this.phoneA
                }).then((res) => {
                    if(res.data.data.code==200){
                        this.createADialog=true
                        this.tableAData=res.data.data.data
                    }
                });
            },
            
            viewDetailA(vid){
                this.postRequest('/appManage/getGrantDetails',{
                    phoneA:this.phoneA,
                    vid:vid
                }).then((res) => {
                    if(res.data.data.code==200){
                        this.grantdetail=true;
                        this.tableDetailData = res.data.data.data
                    }
                });
                
            },
            viewDetailB(vid){
                this.postRequest('/appManage/getWriteDetails',{
                    phoneA:this.phoneA,
                    vid:vid
                }).then((res) => {
                    if(res.data.data.code==200){
                        this.writeDetail=true;
                        this.tableWriteData = res.data.data.data
                    }
                });
            },


            openBDialog(){
                this.postRequest('/appManage/getBVouchersDetails',{
                    phoneB:this.phoneB
                }).then((res) => {
                    if(res.data.data.code==200){
                        this.createBDialog=true
                        this.tableBData=res.data.data.data
                        this.tableCData=res.data.data.allUsersVoucher
                    }
                });
                
            },
            handleSizeChange(val) {
                this.pageSize=val;
                this.getAllUserVouchersDetails();
            },
            handleCurrentChange(val) {
                this.currentPage=val;
                this.getAllUserVouchersDetails();
            }
            
        }
    }
</script>

<style scoped>
    .containerWrapper{
      display: flex;
      flex-direction: row;
    }
</style>
<template>
    <div class="">
        <div>
            <el-select v-model="userStyle" clearable  placeholder="请选择用户类别" 
            @change="searchUsers()"
            style="width:150px;float: left;">
                <el-option label="A类用户" value="A"></el-option>
                <el-option label="B类用户" value="B"></el-option>
            </el-select>
            <el-input placeholder="请输入手机号" v-model="serachPhone" 
                clearable @change="searchUsers()"
                style="width:150px;float: left;"
            ></el-input>

            <span style="float: left;padding-top: 10px;margin-left: 50px;">核销数量：</span>
            <el-input v-model="disNum" 
                clearable 
                style="width:100px;float: left;margin-left: 10px;"
            ></el-input>
            <el-button type="primary" style="float: right;float: left;margin-left: 10px;" @click="confirmDisdroy">确定核销</el-button>
            <!-- <el-button type="primary" style="float: right;float: left;" @click="disdroyList">核销记录</el-button> -->
            <el-button type="primary" style="float: right;float: left;" @click="dialogTableVisible = true">核销记录</el-button>
            

            <el-button type="primary" style="float: right;" @click="createLiveDialog=true">批量修改用户类型<i class="el-icon-edit el-icon--right"></i></el-button>
        </div>
        <div>
            <el-table :data="tableData" border  style="width: 100%;" height="700" 
            ref="multipleTable"
            @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column fixed prop="userid" label="用户ID"> </el-table-column>
                <el-table-column fixed prop="name" label="用户昵称"> </el-table-column>
                <el-table-column prop="phone" label="手机号"> </el-table-column>
                <el-table-column prop="userType" label="用户类型"> </el-table-column>
                <el-table-column prop="grantNum" label="被发券次数(688)"> </el-table-column>
                <el-table-column prop="heartNum" label="小红心数量"> </el-table-column>
                <el-table-column prop="heartCount" label="次数余额"> </el-table-column>
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"  @click="deleteClick(scope.row.userid)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount">
            </el-pagination>
        </div>
        <el-dialog title="批量修改人员类型" :visible.sync="createLiveDialog">
            <el-form :model="form">
                <el-form-item label="人员类型" :label-width="formLabelWidth">
                    <el-select v-model="form.userType" placeholder="请选择人员类型">
                        <el-option label="A" value="A"></el-option>
                        <el-option label="B" value="B"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeWebLiveDialog">取 消</el-button>
                <el-button type="primary" @click="editUserType()">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="核销记录" :visible.sync="dialogTableVisible">
            <el-table :data="disdroyList" style="width: 100%;" height="400" >
                <el-table-column property="disdroyTime" label="核销日期" width="150"></el-table-column>
                <el-table-column property="disdroyNum" label="核销数量" width="200"></el-table-column>
                <el-table-column property="usernum" label="核销人数"></el-table-column>
                <el-table-column fixed="right" label="导出明细">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"  @click="exportRecord(scope.row.disdroyTime,scope.row.disdroyNum)">确定导出</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="currentPage1"
                :page-sizes="pageSizes1"
                :page-size="pageSize1"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount1">
            </el-pagination>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: [],
                createLiveDialog:false,
                form: {
                    userType: ''
                },
                formLabelWidth: '130px',
                currentPage:1,
                pageSizes:[10,20,50,100],
                pageSize:10,
                totalCount:0,

                currentPage1:1,
                pageSizes1:[10,20,50,100],
                pageSize1:10,
                totalCount1:0,

                multipleSelection: [],
                ids:'',
                userStyle:'',
                serachPhone:'',
                disNum:200,
                dataLists:[],
                dialogTableVisible:false,
                disdroyList:[],
            }
        },
        created(){
            this.getAllUsers();
            this.getAllDisdroyList();
        },
        methods: {
            getAllUsers(){
                this.postRequest('/appManage/getAllUsers',{
                    page:this.currentPage,
                    pageSize:this.pageSize
                }).then((res) => {
                    if(res.data.data.code==200){
                        this.tableData=res.data.data.data
                        this.totalCount=res.data.data.totalCount
                    }
                });
            },
            getAllDisdroyList(){
                this.postRequest('/appManage/getDisdroyRecord',{
                    page:this.currentPage1,
                    pageSize:this.pageSize1
                }).then((res) => {
                    if(res.data.data.code==200){
                        this.disdroyList=res.data.data.data
                        this.totalCount1=res.data.data.totalCount
                    }
                });
            },
            searchUsers(){
                console.log("searchUsers.............")
                this.postRequest('/appManage/searchUsers',{
                    page:this.currentPage-1,
                    size:this.pageSize,
                    userType:this.userStyle,
                    phone:this.serachPhone
                }).then((res) => {
                    if(res.data.data.code==200){
                        this.tableData=res.data.data.data
                        this.totalCount=res.data.data.totalCount
                    }
                });
            },
            editUserType(){
                this.postRequest('/appManage/editUserType',{
                    ids:this.ids,
                    userType:this.form.userType
                }).then((res)=>{
                    if(res.data.data.code==200){
                        this.createLiveDialog=false;
                        this.getAllUsers();
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                    }
                    else{
                        this.createLiveDialog=false;
                        this.$message.error('修改失败');
                    }
                })
            },
        
            async exportRecord(desdroyTime,disdroyNum) {
                this.postRequest('/appManage/exportExcel2',{
                    desdroyTime:desdroyTime,
                    disdroyNum:disdroyNum
                }).then((res)=>{
                    console.log("res.data.data.code---->"+res.data.data.code)
                    if(res.data.data.code==200){
                        console.log("res.data.data.base64String---->"+res.data.data.base64String)
                        if (res.data.data.base64String !== null) {
                            var downName="核销数量为"+disdroyNum+'的核销明细.xls'
                            var preFix=desdroyTime.replace("-","").replace(" ","").replace(":","").replace("-","")
                            const el = document.createElement('a')
                            el.setAttribute('href', 'data:xlsx;base64,' + res.data.data.base64String)
                            // el.setAttribute('download', desdroyTime+"核销数量为"+disdroyNum+'的核销明细.xls')
                            el.setAttribute('download', preFix+downName)
                            document.body.append(el)
                            el.click()
                        }
                        this.$message.success('导出成功')
                    }
                    else{
                        this.$message.error('导出失败');
                    }
                })
                
            },
            closeWebLiveDialog(){
                this.createLiveDialog=false;
                // this.cleanForm();
            },
            cleanForm(){
                this.form.name=''
                this.form.description=''
                this.form.videourl=''
                this.form.coverImgUrl=''
                this.form.status=''
            },
            deleteClick(e){
                this.postRequest('/appManage/delUser',{id:e}).then((res)=>{
                    if(res.data.data.code==200){
                        this.getAllUsers();
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                    }
                    else{
                        this.$message.error('删除失败');
                    }
                })
            },
            handleClick(row) {
                this.form=row;
                this.createLiveDialog=true;
            },
            handleSizeChange(val) {
                this.pageSize=val;
                this.getAllUsers();
            },
            handleSizeChange1(val) {
                this.pageSize1=val;
                this.getAllDisdroyList();
            },
            handleCurrentChange(val) {
                this.currentPage=val;
                this.getAllUsers();
            },
            handleCurrentChange1(val) {
                this.currentPage1=val;
                this.getAllDisdroyList();
            },
            handleSelectionChange(val) {
                this.dataLists = val;
                if(val.length>0){
                    var idss=''
                    val.forEach((item) => {
                        idss=idss+item.userid+","
                    })
                    // this.multipleSelection = idss.slice;
                    this.ids=idss.slice(0,-1);
                }
            },
            confirmDisdroy(){
                if(this.dataLists.length>0){
                    this.postRequest('/appManage/confirmDisdroy',
                        {
                            dataLists:this.dataLists,
                            disNum:this.disNum
                        }).then((res)=>{
                        if(res.data.data.code==200){
                            this.getAllUsers();
                            this.$message({
                                message: '核销成功',
                                type: 'success'
                            });
                        }
                        else{
                            this.$message.error('核销失败');
                        }
                    })
                }
                else{
                    this.$message({
                        message: '请选择至少一条数据',
                        type: 'warning'
                    });
                }
            },
        }
    }
</script>

<style scoped>
    .containerWrapper{
      display: flex;
      flex-direction: row;
    }
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import LoginView from '../views/login/LoginView.vue'
import homePage from '../views/homePage/HomePage.vue'
import webLive from '../views/menus/WebLive.vue'
import videos from '../views/menus/Videos.vue'
import users from '../views/menus/Users.vue'
import userVouchersDetails from '../views/menus/UserVouchersDetails.vue'
import vouchers from '../views/menus/Vouchers.vue'
import vouchersDetails from '../views/menus/VouchersDetails.vue'
import goods from '../views/menus/Goods.vue'
import PrivacyPolicy from '../views/privacyPolicy/PrivacyPolicy.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/homePage',
    name: 'homePage',
    component: homePage,
    children:[
      {
        path: 'webLive',
        name: 'webLive',
        component: webLive
      },
      {
        path: 'videos',
        name: 'videos',
        component: videos
      },
      {
        path: 'users',
        name: 'users',
        component: users
      },
      {
        path:'userVouchersDetails',
        name:'userVouchersDetails',
        component:userVouchersDetails
      },
      {
        path:'vouchers',
        name:'vouchers',
        component:vouchers
      },
      {
        path:'vouchersDetails',
        name:'vouchersDetails',
        component:vouchersDetails
      },
      {
        path:'goods',
        name:'goods',
        component:goods
      },
      
    ]
  },
]

const router = new VueRouter({
  routes,
  // mode: "history" 
})


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
 
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

export default router

<template>
    <div class="indexPage">
      <div class="containerWrapper">
        <div class="sidebarWrapper">
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              background-color="#545c64"
              text-color="#fff"
              active-text-color="#ffd04b"
              :collapse="collapseStatus"
            >
              <el-menu-item index="0">
                <i class="el-icon-menu"></i>
                <span slot="title">工作平台</span>
              </el-menu-item>
              <el-submenu index="1">
                  <template slot="title">
                  <i class="el-icon-office-building"></i>
                  <span>首页</span>
                  </template>
                  <el-menu-item-group>
                  <!-- <template slot="title">分组一</template> -->
                  <el-menu-item index="1-1" @click="goTo('/homePage/goods')">商品管理</el-menu-item>
                  </el-menu-item-group>
              </el-submenu>
              <el-submenu index="21">
                  <template slot="title">
                  <i class="el-icon-office-building"></i>
                  <span>直播视频页管理</span>
                  </template>
                  <el-menu-item-group>
                  <el-menu-item index="2-1" @click="goTo('/homePage/webLive')">直播管理</el-menu-item>
                  <el-menu-item index="2-2" @click="goTo('/homePage/videos')">视频管理</el-menu-item>
                  </el-menu-item-group>
              </el-submenu>
              <el-submenu index="3">
                  <template slot="title">
                  <i class="el-icon-user"></i>
                  <span>用户中心</span>
                  </template>
                  <el-menu-item-group>
                  <el-menu-item index="3-1" @click="goTo('/homePage/users')">用户浏览</el-menu-item>
                  <el-menu-item index="3-2" @click="goTo('/homePage/userVouchersDetails')">用户发券详情</el-menu-item>
                  </el-menu-item-group>
              </el-submenu>
              <el-submenu index="4">
                  <template slot="title">
                  <i class="el-icon-present"></i>
                  <span>券中心</span>
                  </template>
                  <el-menu-item-group>
                  <el-menu-item index="4-1" @click="goTo('/homePage/vouchers')">券类管理</el-menu-item>
                  <!-- <el-menu-item index="1-2" @click="goTo('/homePage/vouchersDetails')">发放详情</el-menu-item> -->
                  </el-menu-item-group>
              </el-submenu>
            </el-menu>
        </div>
        <div class="contentWrapper">
          <div class="mainHeader">
            <!--侧边导航栏控制按钮-->
            <!-- <span><i class="el-icon-s-fold" @click="handleFold()"></i></span> -->
            <span><i class="el-icon-s-fold"></i></span>
            <div class="userCenter">
              <el-link :underline="false" href="https://www.baidu.com" class="username">Admin</el-link>
              <el-avatar :size="40" :src="avaUrl"></el-avatar>
            </div>
          </div>
          <div class="mainContent">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HelloWorld',
    data () {
      return {
        isCollapse: false,
        collapseStatus: false,
        avaUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
      }
    },
    methods: {
      handleOpen (key, keyPath) {
        console.log(key, keyPath)
      },
      handleClose (key, keyPath) {
        console.log(key, keyPath)
      },
      handleFold () {
        this.collapseStatus = !this.collapseStatus
      },
      goTo (path) {
        this.$router.replace(path)
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .containerWrapper{
      display: flex;
      flex-direction: row;
    }
    .sidebarWrapper{/*设置侧边栏导航高度*/
      /* min-height: 100vh; */
      text-align: left;
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {/*设置侧边栏不折叠的宽度*/
      width: 250px;
      height: 100%;
      background-color: #545c64;
    }
    .el-menu--collapse{/*设置侧边栏折叠后的高度*/
      height: 100%;
    }
    .indexPage .el-aside{
      width: 250px;/*设置侧边导航栏宽度*/
      overflow-x: hidden;
      overflow-y: hidden;
    }
    .contentWrapper{/*设置内容区包裹样式*/
      flex: 1;
      min-height: 100vh;
    }
    .mainHeader{/*头部样式设置*/
      height: 56px;
      padding: 0 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .mainContent{/*内容区样式设置*/
      /* padding: 0 10px; */
      background: #F0F2F5;
      height: 100%;
    }
    .userCenter{/*头部右侧个人中心*/
      display: flex;
      align-items: center;
    }
    .userCenter .username{
      margin-right: 15px;
    }
  </style>
<template>
    <div class="">
        
        <div style="background:white;">
            <div>
                <el-button type="primary" style="float: right;" @click="createLiveDialog=true">创建商品<i class="el-icon-upload el-icon--right"></i></el-button>
            </div>
            <div style="margin-left: 10px;">
                <el-table :data="tableData" border  style="width: 100%;" height="680">
                    <el-table-column fixed prop="id" label="id" v-if="false"> </el-table-column>
                    <el-table-column fixed prop="name" label="商品名称"> </el-table-column>
                    <el-table-column prop="description" label="商品介绍"> </el-table-column>
                    <el-table-column prop="details" label="商品详情"> </el-table-column>
                    <el-table-column prop="headImg" label="商品头图">
                        <template slot scope="{row,$index}"> 
                            <img :src="row.headImg" alt="" style="height: 100px" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="porder" label="商品优先级"> </el-table-column>
                    <el-table-column fixed="right" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="small"  @click="deleteClick(scope.row.id)">删除</el-button>
                            <el-button @click="handleClick(scope.row)" type="text" size="small">编辑优先级</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pageSizes"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalCount">
                </el-pagination>
            </div>
        </div>
        <el-dialog title="新增商品" :visible.sync="createLiveDialog">
            <el-form :model="form"
            v-loading="loading"
            element-loading-text="正在上传文件....."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            >
                <el-form-item label="商品名称" :label-width="formLabelWidth" >
                    <el-input v-model="form.name" ></el-input>
                </el-form-item>
                <el-form-item label="商品价格" :label-width="formLabelWidth" style="text-align: left;">
                    <el-input v-model="form.price" style="width: 400px;"></el-input>元
                </el-form-item>
                <el-form-item label="商品描述" :label-width="formLabelWidth">
                    <el-input v-model="form.description" ></el-input>
                </el-form-item>
                <el-form-item label="商品详情" :label-width="formLabelWidth">
                    <el-input v-model="form.details" ></el-input>
                </el-form-item>

                <el-form-item label="添加头图" :label-width="formLabelWidth">
                    <el-upload
                        ref="upload"
                        class="upload-demo"
                        action=""
                        :auto-upload="false"
                        :before-remove="beforeRemove"
                        multiple
                        :on-preview="handlePreview"
                        :file-list="fileList"
                        :on-success="handleSuccess"
                        :on-remove="handleRemove"
                        :on-change="onChange"
                        :limit="1"
                        :on-exceed="handleExceed">
                        <el-button size="small" type="primary">选取图片</el-button>
                    </el-upload>
                    
                </el-form-item>
                <el-form-item label="添加详情图" :label-width="formLabelWidth">
                    <el-upload
                        ref="upload"
                        class="uploadDetailImg"
                        action=""
                        :auto-upload="false"
                        :before-remove="beforeRemoveDetailImg"
                        :multiple="true"
                        :on-preview="handlePreviewDetailImg"
                        :file-list="detailFileList"
                        :on-success="handleSuccessDetailImg"
                        :on-remove="handleDetailFileRemove"
                        :on-change="onChangeDetail"
                        :limit="10"
                        :on-exceed="handleExceedDetailImg">
                        <el-button size="small" type="primary">选取图片</el-button>
                    </el-upload>
                    
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeWebLiveDialog">取 消</el-button>
                <el-button type="primary" @click="uploadGoods()">确 定</el-button>
            </div>
        </el-dialog>


        <el-dialog title="编辑优先级" :visible.sync="editOrderFlag">
            <el-form :model="editOrder">
                <el-form-item label="优先级" :label-width="formLabelWidth" style="text-align: left;margin-left: 150px;">
                    <el-input v-model="editOrder.porder" style="width: 200px;"></el-input>
                </el-form-item>
            </el-form>
        
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeWebLiveDialog">取 消</el-button>
                <el-button type="primary" @click="uploadGoodsPorder()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: [],
                createLiveDialog:false,
                form: {
                    name: '',
                    price: '',
                    description: '',
                    details:'',
                    headImg:'',
                    imgs:''
                },
                editOrderFlag:false,
                editOrder:{
                    id:'',
                    porder:''
                },
                formLabelWidth: '130px',
                formLabelWidth1: '90px',
                currentPage:1,
                pageSizes:[10,20,50,100],
                pageSize:10,
                totalCount:0,
                formdata:[],
                fileList:[],
                loading:false,
                formdetailImgdata:[],
                detailFileList:[]
            }
        },
        created(){
            this.getAllProducts();
        },
        methods: {
            getAllProducts(){
                this.postRequest('/appManage/getAllProducts',{
                    page:this.currentPage,
                    pageSize:this.pageSize
                }).then((res) => {
                    if(res.data.data.code==200){
                        this.tableData=res.data.data.data
                        this.totalCount=res.data.data.totalCount
                    }
                });
            },
            uploadGoods(){
                this.loading=true
                this.postRequest('/fileUpload/pictureUploadMore',this.formdetailImgdata).then((res)=>{
                    if(res.data.data.code==200){
                        this.form.imgs=res.data.data.headImg
                        this.postRequest('/fileUpload/pictureUpload',this.formdata).then((res)=>{
                            if(res.data.data.code==200){
                                this.form.headImg=res.data.data.ossPath
                                this.postRequest('/appManage/saveGoods',this.form).then((res)=>{
                                    if(res.data.data.code==200){
                                        this.createLiveDialog=false;
                                        this.loading=false
                                        this.getAllProducts();
                                        this.$message({
                                            message: '上传成功',
                                            type: 'success'
                                        });
                                        this.cleanForm();
                                    }
                                    else{
                                        this.createLiveDialog=false;
                                        this.loading=false
                                        this.$message.error('上传失败');
                                    }
                                })
                            }
                            else{
                                this.createLiveDialog=false;
                                this.loading=false
                                this.$message.error('上传失败');
                            }
                        })
                    }
                    else{
                        this.createLiveDialog=false;
                        this.loading=false
                        this.$message.error('上传失败');
                    }
                })
                
            },
            closeWebLiveDialog(){
                this.createLiveDialog=false;
                this.editOrderFlag=false;
                this.loading=false
                this.cleanForm();
            },
            deleteClick(e){
                this.postRequest('/appManage/delProduct',{id:e}).then((res)=>{
                    if(res.data.data.code==200){
                        this.getAllProducts();
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                    }
                    else{
                        this.$message.error('删除失败');
                    }
                })
            },
            handleClick(row) {
                this.form=row;
                this.editOrder.id=row.id
                this.editOrderFlag=true;
            },
            uploadGoodsPorder(){
                if(this.editOrder.porder.length>0){
                    this.postRequest('/appManage/editProductPorder',this.editOrder).then((res)=>{
                    if(res.data.data.code==200){
                        this.getAllProducts();
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        this.editOrderFlag=false;
                    }
                    else{
                        this.$message.error('修改失败');
                        this.editOrderFlag=false;
                    }
                })
                }
            },
            handleSizeChange(val) {
                this.pageSize=val;
                this.getAllProducts();
            },
            handleCurrentChange(val) {
                this.currentPage=val;
                this.getAllProducts();
            },

            onChange(file,fileList) {
                //通常文件上传是要用 FormData 格式的
                this.formdata = new FormData()
                this.formdata.append('file', file.raw)
                this.formdata.append('name', this.name)
                this.formdata.append('ossPath', 'https://assets.yixinger.com/img/')
                this.formdata.append('objectName', 'img/')
            },
            handleRemove(file, fileList) {
                this.fileList = fileList
                // 判断溢出的文件是否当前预览中的文件
                if (fileList.findIndex(item => item.uid === this.currentFile.uid) === -1) {
                    this.currentFile = null
                }
            },
            handleDetailFileRemove(file, fileList) {
                this.detailFileList = fileList
                // 判断溢出的文件是否当前预览中的文件
                if (fileList.findIndex(item => item.uid === this.currentFile.uid) === -1) {
                    this.currentFile = null
                }
            },
            handlePreview(file) {
                this.currentFile = file
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length}个文件，共选择了 ${files.length + fileList.length}个文件`)
            },
            handleExceedDetailImg(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length}个文件，共选择了 ${files.length + fileList.length}个文件`)
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name} ？`)
            },
            handleSuccess(response, file, fileList) {
                onsole.log("fileList---->"+fileList)
                //this.files.push(response)
            },



            onChangeDetail(file,fileList) {
                this.formdetailImgdata = new FormData()
                fileList.forEach((file, index) => {
                    this.formdetailImgdata.append('file', file.raw)
                    this.formdetailImgdata.append('name', this.name)
                    this.formdetailImgdata.append('ossPath', 'https://assets.yixinger.com/img/')
                    this.formdetailImgdata.append('objectName', 'img/')
                });
                
            },
            
            handlePreviewDetailImg(file) {
                this.currentFile = file
            },
            handleExceedVideo(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length}个文件，共选择了 ${files.length + fileList.length}个文件`)
            },
            beforeRemoveDetailImg(file, fileList) {
                return this.$confirm(`确定移除 ${file.name} ？`)
            },
            handleSuccessDetailImg(response, file, fileList) {
                onsole.log("fileList---->"+fileList)
                //this.files.push(response)
            },
            cleanForm(){
                this.form.name=''
                this.form.price=''
                this.form.description=''
                this.form.details=''
                this.form.headImg=''
                this.form.imgs=''
                this.formdata=[]
                
                this.fileList=[]
                this.formdetailImgdata=[]
                this.detailFileList=[]
            },
        }
    }
</script>

<style scoped>
    .containerWrapper{
      display: flex;
      flex-direction: row;
    }
</style>
<template>
    <div class="">
        <el-button type="primary" style="float: right;" @click="createLiveDialog=true">新增券类<i class="el-icon-plus el-icon--right"></i></el-button>
        <div>
            <el-table :data="tableData" border  style="width: 100%;" height="600">
                <el-table-column fixed prop="vid" label="vid" v-if="false"> </el-table-column>
                <el-table-column fixed prop="name" label="券类名称"> </el-table-column>
                <el-table-column prop="description" label="券类说明"> </el-table-column>
                <el-table-column prop="coverImg" label="券类封面图">
                    <template slot scope="{row,$index}"> 
                        <img :src="row.coverImg" alt="" style="height: 100px" />
                    </template>
                </el-table-column>
                <el-table-column prop="validity" label="有效期"> </el-table-column>
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"  @click="deleteClick(scope.row.vid)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount">
                </el-pagination>
        </div>
        <el-dialog title="新增券类" :visible.sync="createLiveDialog">
            <el-form :model="form" enctype="multipart/form-data">
                <el-form-item label="券类名称" :label-width="formLabelWidth">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="券类说明" :label-width="formLabelWidth">
                    <el-input v-model="form.description" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="有效期" :label-width="formLabelWidth">
                    <el-input v-model="form.validity" autocomplete="off" style="width: 80px;"></el-input>
                    <span>天</span>
                </el-form-item>
                <el-form-item label="券类封面图" :label-width="formLabelWidth">
                    <el-upload
                        ref="upload"
                        class="upload-demo"
                        :action="action"
                        :auto-upload="false"
                        :before-remove="beforeRemove"
                        multiple
                        :on-preview="handlePreview"
                        :file-list="fileList"
                        :on-success="handleSuccess"
                        :on-remove="handleRemove"
                        :on-change="onChange"
                        :limit="1"
                        :on-exceed="handleExceed">
                        <el-button size="small" type="primary">选取附件</el-button>
                    </el-upload>

                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeWebLiveDialog">取 消</el-button>
                <el-button type="primary" @click="saveVouchers()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: [],
                createLiveDialog:false,
                form: {
                    name: '',
                    description: '',
                    coverImg: '',
                    validity:'',
                    file:[]
                },
                formLabelWidth: '130px',
                currentPage:1,
                pageSizes:[10,20,50,100],
                pageSize:10,
                totalCount:0,
                dialogFormVisible: false,

                action: '/fileUpload/pictureUpload',
                fileList: [],
                currentFile: null,
                files: [],
                formdata:[]
            }
        },
        created(){
            this.getAllVouchers();
        },
        methods: {
            getAllVouchers(){
                this.postRequest('/appManage/getAllVouchers',{
                    page:this.currentPage,
                    pageSize:this.pageSize
                }).then((res) => {
                    if(res.data.data.code==200){
                        this.tableData=res.data.data.data
                        this.totalCount=res.data.data.totalCount
                    }
                });
            },
            saveVouchers(){
                this.postRequest('/fileUpload/pictureUpload',this.formdata).then((res)=>{
                    if(res.data.data.code==200){
                        this.form.coverImg=res.data.data.ossPath
                        this.postRequest('/appManage/saveVouchers',this.form).then((res)=>{
                            if(res.data.data.code==200){
                                this.createLiveDialog=false;
                                this.getAllVouchers();
                                this.$message({
                                    message: '新增成功',
                                    type: 'success'
                                });
                            }
                            else{
                                this.createLiveDialog=false;
                                this.$message.error('创建失败');
                            }
                        })
                    }
                    else{
                        this.createLiveDialog=false;
                        this.$message.error('创建失败');
                    }
                })

                
            },
            closeWebLiveDialog(){
                this.createLiveDialog=false;
                // this.cleanForm();
            },
            cleanForm(){
                this.form.name=''
                this.form.description=''
                this.form.videourl=''
                this.form.coverImgUrl=''
                this.form.status=''
            },
            deleteClick(e){
                this.postRequest('/appManage/delVouchers',{id:e}).then((res)=>{
                    if(res.data.data.code==200){
                        this.getAllVouchers();
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                    }
                    else{
                        this.$message.error('删除失败');
                    }
                })
            },
            handleClick(row) {
                this.form=row;
                this.createLiveDialog=true;
            },
            handleSizeChange(val) {
                this.pageSize=val;
                this.getAllVouchers();
            },
            handleCurrentChange(val) {
                this.currentPage=val;
                this.getAllVouchers();
            },

            onChange(file,fileList) {
                this.form.file=file
                //通常文件上传是要用 FormData 格式的
                this.formdata = new FormData()
                this.formdata.append('file', file.raw)
                this.formdata.append('name', this.name)
                this.formdata.append('ossPath', 'https://assets.yixinger.com/img/')
                this.formdata.append('objectName', 'img/')
            },
            handleRemove(file, fileList) {
                this.fileList = fileList
                // 判断溢出的文件是否当前预览中的文件
                if (fileList.findIndex(item => item.uid === this.currentFile.uid) === -1) {
                    this.currentFile = null
                }
            },
            handlePreview(file) {
                this.currentFile = file
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length}个文件，共选择了 ${files.length + fileList.length}个文件`)
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name} ？`)
            },
            handleSuccess(response, file, fileList) {
                onsole.log("fileList---->"+fileList)
                this.files.push(response)
            },

        }
    }
</script>

<style scoped>
    .containerWrapper{
      display: flex;
      flex-direction: row;
    }
</style>